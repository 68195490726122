<template>
  <div class="content-administrators">
    <div class="content-global content-tables-administrator">
      <div ref="searchbarGlobal">
        <header-component-global sTextTitle="Administradores" sTextBtnAdd="Crear administrador +"
          :bAdminPermissions="bAdminAdministrator" setDialog="setDialogAddAdministrator"
          @setDialogAdd="setDialogAddAdministrator" />
      </div>
      <div ref="filterGlobal">
        <filter-component-global class="global-filters" labelName="Buscador de administrador"
          placeholderName="Ingrese nombre, teléfono o correo electrónico..." @setSearch="setSearch"
          @setSortName="setSortName" @setSortDate="setSortDate" />
      </div>
      <div dark class="global-content-table">
        <no-data-text-component-global :iHeight="screenHeight" :sTextEmpty="sTextEmpty" v-if="bEmptyTable"
          :bAdminPermissions="bAdminAdministrator" setDialog="setDialogAddAdministrator"
          @setDialogAdd="setDialogAddAdministrator" />
        <v-data-table v-else v-show="!isMobile" mobile-breakpoint="0" :headers="headers" :disable-sort="true"
          :items="aTable" :loading="bLoadingTable" loading-text="Cargando datos..." :items-per-page="iItemsPerPage"
          :hide-default-footer="true" no-data-text="No hay información disponible para mostrar." :footer-props="{
            'items-per-page-text': 'Filas por página',
            'items-per-page-all-text': 'Todos',
          }" class="global-table">
          <template slot="progress">
            <v-progress-linear color="var(--primary-color-loading-table)" indeterminate></v-progress-linear>
          </template>
          <template v-slot:[`item.accions`]="{ item }">
            <v-btn @click="detailAdministrator(item)" class="global-btn-table-detail" icon>
              <v-icon> mdi-eye-outline </v-icon>
            </v-btn>
            <v-btn v-show="bAdminAdministrator" @click="deleteItem(item)" class="global-btn-table-delete" icon>
              <v-icon> mdi-trash-can-outline </v-icon>
            </v-btn>
          </template>
          <template v-slot:footer>
            <footer-table-component-global :numPages="numPages" :totalItems="totalItems"
              @changeTable="getEmitAdministrators" />
          </template>
        </v-data-table>
        <table-mobile-administrators-component v-show="isMobile" @changeTable="getEmitAdministrators"
          @openDetailItem="detailAdministrator" @openDeleteItem="deleteItem" :aTable="aTable" :headers="headers"
          :numPages="numPages" :totalItems="totalItems" />
      </div>
    </div>
    <dialog-add-administrator-component :bDialogAddAdministrator="bDialogAddAdministrator"
      @setDialogAddAdministrator="setDialogAddAdministrator" />
    <delete-component-global @setDialogDelete="deleteItem" :oDialogDelete="oDialogDelete"
      :bDialogDelete="bDialogDelete" />
    <!-- <footer-component-global /> -->
  </div>
</template>

<script>
export default {
  name: "administrators",
  data() {
    return {
      sSearch: "",
      screenHeight: 0,
      heightMax: 0,
      screenWidth: 0,
      bLoadingTable: true,
      bEmptyTable: false,
      iCurrentPage: 1,
      iPageNumber: 1,
      iItemsPerPage: 30,
      numPages: 0,
      totalItems: 0,
      totalView: 0,
      sTextEmpty: "",

      headers: [
        {
          text: "Nombre",
          sortable: false,
          value: "sFullName",
          class: "global-header-table global-header-name",
          cellClass: "global-body-table global-body-name ",
        },
        {
          text: "Teléfono",
          sortable: false,
          value: "sPhoneNumber",
          class: "global-header-table global-header-phone",
          cellClass: "global-body-table",
        },
        {
          text: "Correo electrónico",
          sortable: false,
          value: "sEmail",
          class: "global-header-table global-header-email",
          cellClass: "global-body-table",
        },
        {
          text: "Ocupación laboral",
          sortable: false,
          value: "sRole",
          class: "global-header-table global-header-role",
          cellClass: "global-body-table ",
        },
        {
          text: "Fecha de ingreso",
          value: "tCreatedAt",
          class: "global-header-table  w-200",
          cellClass: "global-body-table w-200 ",
        },
        {
          text: "Acciones",
          value: "accions",
          align: "center",
          class:
            "global-header-table global-header-accions global-col-header-accion",
          cellClass:
            "global-body-table global-body-accions global-col-body-accion",
        },
      ],
      aTable: [],
      aMonth: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      fechaIngreso: "",
      textAlfabetico: "",
      isMobile: false,
      bDialogAddAdministrator: false,
      bDialogDelete: false,
      oDialogDelete: {
        active: false,
        textTitle: "",
        textDescription: "",
        textQuestion: "",
        api: "",
        returnToView: false,
      },
    };
  },
  beforeMount() {
    this.$store.commit("setTabPosition", { sTabPosition: 0 });
    this.$store.commit("setTabPositionOrderPayable", 0)

    this.setTextEmpty();
    this.getAdministrators();
  },
  updated() {
    this.matchHeight();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    window.addEventListener("resize ", this.matchHeight);
    this.matchHeight();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    window.addEventListener("resize ", this.matchHeight);
  },
  methods: {
    setSortName(val) {
      this.textAlfabetico = val;
    },
    setSortDate(val) {
      this.fechaIngreso = val;
    },
    setTextEmpty() {
      let sTextemptyTemp = " <p>No existen administradores registrados.</p>"
      this.bAdminAdministrator ? sTextemptyTemp += "<p>Comienza <span  class='text-information-link'>registrando uno.</span> </p>" : ""
      this.sTextEmpty = sTextemptyTemp;
    },

    //#region RESIZE PAGE
    matchHeight() {
      this.$nextTick((e) => {
        if (this.$refs.searchbarGlobal !== undefined) {
          this.heightMax =
            this.$refs.searchbarGlobal.clientHeight +
            this.$refs.filterGlobal.clientHeight;
          this.heightMax = this.heightMax + 90; //123 IS NAVBAR
        }
        if (window.innerWidth > 599.99) {
          this.isMobile = false;
          this.screenHeight = window.innerHeight - this.heightMax;
        } else {
          this.isMobile = true;
          this.screenHeight = window.innerHeight - this.heightMax;
        }
      });
    },
    handleResize: function () {
      this.matchHeight();
    },
    //#endregion RESIZE PAGE
    setDialogAddAdministrator() {
      this.bDialogAddAdministrator = !this.bDialogAddAdministrator;
    },
    deleteItem(item) {
      if (item) {
        this.oDialogDelete.textTitle = "Eliminar administrador";
        this.oDialogDelete.textDescription =
          "La eliminación de un administrador es una acción irreversible.";
        this.oDialogDelete.textQuestion =
          "Acepto la responsabilidad y deseo proseguir con la eliminación del administrador: " +
          item.sName +
          ".";
        this.oDialogDelete.api = `${URI}/api/sp/v1/users/${item.sUserId}`;
      }
      this.bDialogDelete = !this.bDialogDelete;
    },
    detailAdministrator(item) {
      this.$router
        .push(`/home/administrators-detail/${item.sUserId}`)
        .catch(() => { });
    },
    getEmitAdministrators(obj) {
      this.iCurrentPage = obj.iCurrentPage;
      this.iItemsPerPage = obj.iItemsPerPage;
      this.getAdministrators();
    },
    getAdministrators() {
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          DB.get(`${URI}/api/sp/v1/users`, {
            params: {
              iPageNumber: this.iCurrentPage,
              iItemsPerPage: this.iItemsPerPage,
              sSearch: this.sSearch,
              sSortName: this.textAlfabetico,
              sSortDate: this.fechaIngreso,
            },
            headers: {
              Authorization: "Bearer " + this.$store.state.sToken,
            },
          })
            .then((response) => {
              this.numPages = response.data.numPages;
              this.totalItems = response.data.total;
              this.aTable =
                response.data.results.map((e) => {
                  return {
                    ...e,
                    sFullName: e.sName + " " + e.sLastname,
                    tCreatedAt: e.tCreatedAt,
                    sPhoneNumber: this.formatPhone(e.oContactInfo),
                  };
                });
              this.bLoadingTable = false;
              this.bEmptyTable = this.aTable.length < 1;

              this.totalView = this.aTable.length;
              this.$store.commit("refresher", false);
            })
            .catch((error) => {
              this.bLoadingTable = false;
              this.Error(error.response.data);
            });
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    formatPhone(oContactInfo) {
      let sCountryCallingCode = oContactInfo.sCountryCallingCode;
      switch (sCountryCallingCode) {
        case "52":
          return (
            "+" +
            oContactInfo.sCountryCallingCode +
            " (" +
            oContactInfo.sAreaCallingCode +
            ") " +
            [
              oContactInfo.sPhoneNumber.slice(0, 4),
              "-",
              oContactInfo.sPhoneNumber.slice(4),
            ].join("") +
            (oContactInfo.sPhoneExtension
              ? " ext. " + oContactInfo.sPhoneExtension + ""
              : "")
          );

        case "1":
          return (
            "+" +
            oContactInfo.sCountryCallingCode +
            " (" +
            oContactInfo.sAreaCallingCode +
            ") " +
            [
              oContactInfo.sPhoneNumber.slice(0, 3),
              "-",
              oContactInfo.sPhoneNumber.slice(3),
            ].join("") +
            (oContactInfo.sPhoneExtension
              ? " ext. " + oContactInfo.sPhoneExtension + ""
              : "")
          );

        default:
          break;
      }
    },
    setSearch(sSearch) {
      this.sSearch = sSearch;
    },
    // FUNCTION OF FOOTER TABLE
    changeTable(e) {
      this.iItemsPerPage = e;
      this.iCurrentPage = 1;
      this.getAdministrators();
    },
    backPage() {
      if (this.iCurrentPage > 1) {
        this.iCurrentPage--;
      }
    },
    nextPage() {
      if (this.iCurrentPage < this.numPages) {
        this.iCurrentPage++;
      }
    },
    firstPage() {
      this.iCurrentPage = 1;
    },
    lastPage() {
      this.iCurrentPage = this.numPages;
    },
  },
  computed: {
    refreshTable() {
      return this.$store.state.refresh;
    },
    bShowAdministrator() {
      return this.$store.state.bShowAdministrator;
    },
    bAdminAdministrator() {
      return this.$store.state.bAdminAdministrator;
    },
  },
  watch: {
    textAlfabetico: function () {
      this.getAdministrators();
    },
    fechaIngreso: function () {
      this.getAdministrators();
    },
    refreshTable: function () {
      this.getAdministrators();
    },
    sSearch() {
      this.iCurrentPage = 1;
      this.getAdministrators();
    },
    iCurrentPage: function () {
      this.getAdministrators();
    },
    bAdminAdministrator() { },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .content-global { */
/* background-color: coral; */
/* } */
.content-administrators {
  /* background-color: darkslategrey; */
  padding: 0px 30px 0px 35px;
}
</style>
